$body-bg: black; // transparent; // darken(#377bc2, 10);
$card-bg: #377bc2;

@import '~bootstrap/scss/bootstrap.scss';

:root {
  --bs-body-font-family: 'Roboto', sans-serif;
}

body {
  background: rgb(2,0,36);
  background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,0,0,1) 100%);
}

.App {
  height: 100vh;
}

.main {
  min-height: calc(100vh - 200px);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
